import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { VStack, Flex } from '@chakra-ui/react';
import { VscHome, VscOrganization, VscNewFolder, VscReport, VscNote,VscHubot } from 'react-icons/vsc';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import {MdOutlineMotionPhotosAuto} from 'react-icons/md';

const MobileNav = styled(Flex)`
width: 100%;
flex-direction: column;
align-items : baseline;
cursor: pointer;
padding: 8px 10px;
border-radius: 5px;
transition: all 200ms ease-in-out;

div&:hover {
        background-color: #eee;
    }
}

ul, a {
  width: 100%;
}

ul {
  padding-left: 30px;
  padding-top: 10px;
  line-height: 28px;

  > a > li {
      list-style: none;
      &:hover {
          font-weight: 600;
      }
  }   
}

`


export const DrawerMenus = ({onClose}) => {
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  return (
    <VStack spacing="10px" align="flex-start" h={'100vh'} mt='30px'>
      <MobileNav>
        <Link to="/" onClick={onClose}>
          <Flex align={'center'} gap='20px'>
            <VscHome size='1.3em' /> Home
          </Flex>
        </Link>
      </MobileNav>

      <MobileNav>
        <Flex
          align={'center'}
          gap='15px'
          justify={'space-between'}
          onClick={() => {
            setToggle1(!toggle1);
          }}
        >
          <VscOrganization size='1.3em' />
          회원
          <ChevronDownIcon />
        </Flex>
        {toggle1 &&
          <ul>
            <Link to="/members" onClick={onClose}>
              <li>회원 현황 조회</li>
            </Link>
            <Link to="/paymentlog" onClick={onClose}>
              <li>결제 현황 조회</li>
            </Link>
            <Link to="/loginlog" onClick={onClose}>
              <li>로그인 기록 조회</li>
            </Link>
          </ul>
        }
      </MobileNav>

      <MobileNav>
        <Link to="/questions" onClick={onClose}>
          <Flex align={'center'} gap='20px'>
            <VscNewFolder size='1.3em' />
            문의사항
          </Flex>
        </Link>
      </MobileNav>

      <MobileNav>
        <Link to="/prompts" onClick={onClose}>
          <Flex align={'center'} gap='20px'>
            <VscReport />
            프롬프트
          </Flex>
        </Link>
      </MobileNav>

      <MobileNav>
        <Link to="/chat_prompts" onClick={onClose}>
          <Flex align={'center'} gap='20px'>
            <VscHubot />
            채팅 프롬프트
          </Flex>
        </Link>
      </MobileNav>

      <MobileNav>
        <Flex
          align={'center'}
          gap='15px'
          justify={'space-between'}
          onClick={() => {
            setToggle2(!toggle2);
          }}
        >
          <VscNote size='1.3em' />
          시리얼넘버
          <ChevronDownIcon />
        </Flex>
        {toggle2 &&
          <ul>
            <Link to="/createSerial" onClick={onClose}>
              <li>시리얼넘버 생성</li>
            </Link>
            <Link to="/getSerial" onClick={onClose}>
              <li>시리얼넘버 조회</li>
            </Link>
          </ul>
        }
      </MobileNav>


      <MobileNav>
        <Flex
          align={'center'}
          gap='15px'
          justify={'space-between'}
          onClick={() => {
            setToggle3(!toggle3);
          }}
        >
          <MdOutlineMotionPhotosAuto size='1.3em' />
          AUTO-GPT
        </Flex>
        {toggle3 &&
          <ul>
            <Link to="/autogpt/createSerial" onClick={onClose}>
              <li>시리얼넘버 생성</li>
            </Link>
            <Link to="/autogpt/getSerial" onClick={onClose}>
              <li>시리얼넘버 조회</li>
            </Link>
          </ul>
        }
      </MobileNav>
    </VStack>
  )
}