import React, { Suspense, useCallback, useEffect, useState } from 'react';
import * as server from 'config/Config';
import {
  Box,
  Button,
  Flex,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';
import { AdminLogOut } from 'Hook/CheckedValid';
import { AddIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { Pagination } from 'Common/Pagination';
import IsDeleteModal from 'Common/IsDeleteModal';
import AddGroupModal from './AddGroupModal';
import GroupListTable from './GroupListTable';

const GroupsList = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem('admin'));

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); //현재 페이지;
  const [maxPage, setMaxPage] = useState(0);
  const offset = (currentPage - 1) * 30;

  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [idList, setIdList] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState('');
  const [isExistEmail, setIsExistEmail] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);

  const openAddGroupModal = () => {
    setIsAddGroupModalOpen(true);
    setIsExistEmail(null);
  };

  const DeleteGroups = async () => {
    try {
      const checkedArray = idList.filter(item => checkedItems.includes(item));

      if (checkedArray.length === 0) {
        toast({
          title: '선택한 유저가 없어요!',
          description: '삭제할 유저를 선택해주세요.',
          position: 'top-right',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      await axios.delete(`${server.SERVER_URL}/group`, {
        data: {
          uids: checkedArray, // 삭제할 그룹의 고유키 리스트
        },
        headers: {
          Authorization: `Bearer ${admin.token}`,
        },
      });

      setSearchList(
        searchList.filter(group => !checkedArray.includes(group.uid))
      );

      setIdList(idList.filter(id => !checkedArray.includes(id)));

      toast({
        title: '그룹 삭제 성공',
        description: '그룹이 삭제되었습니다.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      toast({
        title: '에러 발생',
        description: '그룹 삭제시 에러가 발생했습니다.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    onClose();
  };

  const fetchData = useCallback(async () => {
    const config = {
      method: 'get',
      url: `${server.SERVER_URL}/group`,
      headers: { Authorization: `Bearer ${admin.token}` },
    };
    setLoading(true);
    await axios(config)
      .then(response => {
        setSearchList(response.data.data.groups);
        const orderList = response.data.data.groups.sort(
          (a, b) => new Date(b.create_at) - new Date(a.create_at)
        );
        setSearchList(orderList);

        setIdList(orderList.map(groupInfo => groupInfo.uid));
        setMaxPage(response.data.config.maxPage);
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [admin.token, toast]);

  useEffect(() => {
    // 토큰 상태 검증 및 갱신 로직
    const verifyAndRefreshToken = async () => {
      try {
        await verifyTokenAndRefresh();
        // 토큰 상태가 유효하면 데이터를 불러오는 로직 계속 진행
        fetchData();
      } catch (error) {
        // 토큰 상태 검증 실패 시의 로직 (예: 사용자 로그아웃 처리 및 로그인 페이지로 리다이렉션)
        console.error('토큰 검증 및 갱신 중 오류 발생:', error);
        toast({
          title: '인증 오류',
          description: '세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        navigate('/login'); // 로그인 페이지로 리다이렉션
      }
    };

    verifyAndRefreshToken();
  }, [navigate, toast, fetchData, currentPage]);

  return (
    <>
      <Box className="MemberContainer">
        <h1
          style={{
            maxWidth: '1300px',
            margin: '0 auto',
            paddingBottom: '20px',
            fontSize: '20px',
          }}
        >
          그룹 관리
        </h1>
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Box margin="15px 0">
            <form
              onSubmit={async e => {
                e.preventDefault();
                setCurrent(1);
                // TODO: 그룹 리스트 검색 기능
                setLoading(true);
                const getGroupsByNameResponse = await axios.get(
                  `${server.SERVER_URL}/group/search?name=${keyword || null}`,
                  {
                    headers: {
                      Authorization: `Bearer ${admin.token}`,
                    },
                  }
                );

                setSearchList(getGroupsByNameResponse.data.data.groups);
                setIdList(
                  getGroupsByNameResponse.data.data.groups.map(
                    group => group.uid
                  )
                );
                setLoading(false);
              }}
            >
              <Flex
                className="SearchFlex"
                alignItems="center"
                justify="space-between"
                direction={{ base: 'column', sm: 'row' }}
              >
                <input
                  type={'text'}
                  placeholder="검색할 키워드를 입력해주세요"
                  value={keyword || ''}
                  onChange={e => {
                    e.stopPropagation();
                    setKeyword(e.target.value);
                  }}
                />
                <Button type="submit">
                  <SearchIcon />
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Flex justify="flex-end" mb={25} spacing="15px">
          <AddIcon
            onClick={openAddGroupModal}
            w={5}
            h={5}
            style={{ cursor: 'pointer', marginRight: '15px' }}
          />
          <DeleteIcon
            onClick={onOpen}
            w={5}
            h={5}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <GroupListTable
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                idList={idList}
                searchList={searchList}
              />
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination
          total={maxPage}
          setCurrent={setCurrent}
          currentPage={currentPage}
          toast={toast}
        />
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeleteGroups} />
      <AddGroupModal
        isAddGroupModalOpen={isAddGroupModalOpen}
        setIsAddGroupModalOpen={setIsAddGroupModalOpen}
        setIsExistEmail={setIsExistEmail}
        isExistEmail={isExistEmail}
        setSearchList={setSearchList}
        searchList={searchList}
        setIdList={setIdList}
      />
    </>
  );
};

export default GroupsList;
