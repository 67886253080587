import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Checkbox,
  useToast,
  HStack,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import { PlusSquareIcon, DeleteIcon } from '@chakra-ui/icons';
import * as config from 'config/Config';
import IsDeleteModal from 'Common/IsDeleteModal';
import { Pagination } from 'Common/Pagination';
import ChatPromptTable from './ChatPromptTable';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';

const ChatPrompts = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [idList, setIdList] = useState([]);
  //프롬프트 리스트
  const [List, setList] = useState([]);
  //현재 페이지
  const [currentPage, setCurrent] = useState(1);
  const offset = (currentPage - 1) * 30;

  //페이지당 포스트 개수
  const [postPerPage, setPostPerPage] = useState(100);

  const isIndeterminate = checkedItems.some(Boolean);

  const CheckAll = e => {
    setCheckedItems(e.target.checked ? idList : []);
  };

  const CheckEach = (e, id) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== id));
    }
  };

  const DeletePrompt = async () => {
    const checkedArray = idList.filter(item => checkedItems.includes(item));

    if (checkedArray.length === 0) {
      toast({
        title: '선택한 프롬프트가 없어요!',
        description: '삭제할 프롬프트를 선택해주세요.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    }

    if (checkedArray.length === 1) {
      axios
        .delete(`${config.SERVER_URL}/prompt_npc/${checkedArray[0]}`, {
          headers: { Authorization: `Bearer ${admin.token}` },
        })
        .then(response => {
          navigate(0);
        })
        .catch(error => {
          console.error(error.response);
          toast({
            title: 'error!',
            description: `${error.message}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }

    if (checkedArray.length > 1) {
      Promise.all(
        checkedArray.map(async param => {
          return await axios.delete(
            `${config.SERVER_URL}/prompt_npc/${param}`,
            {
              headers: { Authorization: `Bearer ${admin.token}` },
            }
          );
        })
      )
        .then(response => {
          navigate(0);
        })
        .catch(error => {
          console.error(error.response);
          toast({
            title: 'error!',
            description: `${error.message}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await axios
      .get(
        `${config.SERVER_URL}/prompt_npc/list?page=${currentPage}&count=${postPerPage}`,
        {
          headers: { Authorization: `Bearer ${admin.token}` },
        }
      )
      .then(response => {
        const list = response.data.data;

        const orderList = list.sort(
          (a, b) => new Date(b.update_at) - new Date(a.update_at)
        );
        let idList = [];
        const ids = list.map((item, i) => (idList[i] = item.uid));
        setList(orderList);
        setIdList(ids);
      })
      .catch(error => {
        console.error(error.response);
        if (error.response.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // 토큰 검증 및 갱신 로직
    const checkAndRefreshToken = async () => {
      try {
        await verifyTokenAndRefresh();
        // 토큰이 성공적으로 갱신되었거나 여전히 유효한 경우, 데이터를 불러오는 로직을 계속 진행
        fetchData();
      } catch (error) {
        console.error('토큰 검증 및 갱신 중 오류 발생:', error);
        toast({
          title: '인증 오류',
          description: '세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        navigate('/'); // 로그인 페이지로 리다이렉션
      }
    };

    checkAndRefreshToken();
  }, [navigate, toast]);

  return (
    <>
      <Box maxW="1600px" m="0 auto" padding={{ base: '48px 24px', md: '48px' }}>
        <HStack justify="flex-end" mb={25} spacing="15px">
          <Link to="/chat_prompts/create">
            <PlusSquareIcon w={5} h={5} style={{ cursor: 'pointer' }} />
          </Link>
          <DeleteIcon
            onClick={onOpen}
            w={5}
            h={5}
            style={{ cursor: 'pointer' }}
          />
        </HStack>
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <table className="CustomTableStyle">
                <thead>
                  <tr className="Custom-tr Custom-thead-tr">
                    <th className="CheckBox textCenter">
                      <Checkbox
                        name="all"
                        value="all"
                        colorScheme="blue"
                        isChecked={checkedItems.length === idList.length}
                        isIndeterminate={isIndeterminate}
                        onChange={CheckAll}
                      />
                    </th>
                    <th className="Custom-th1 textLeft">서비스 항목</th>
                    <th className="Custom-th2">작성자</th>
                    <th className="Custom-th3">작성일자</th>
                    <th className="Custom-th3">최종수정일자</th>
                    <th className="Custom-th4">상세보기</th>
                  </tr>
                </thead>
                <ChatPromptTable
                  offset={offset}
                  List={List}
                  checkedItems={checkedItems}
                  CheckEach={CheckEach}
                />
              </table>
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination
          setCurrent={setCurrent}
          currentPage={currentPage}
          toast={toast}
          total={List.length}
        />
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeletePrompt} />
    </>
  );
};

export default ChatPrompts;
