import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Flex,
  useToast,
  useDisclosure,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Back } from 'styles/ComponentStyle';
import * as server from 'config/Config';
import { BtnBox, ModifyBtn, DeleteCancelBtn } from 'styles/ComponentStyle';
import dayjs from 'dayjs';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';

const QuestionBox = styled(Flex)`
  background-color: #fff;
  padding: 15px;
  gap: 50px;
  border-bottom: 1px solid #ededed;

  > h4 {
    font-weight: 600;
    min-width: 60px;
  }

  > p {
    max-width: 800px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
`;

const QuestionLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  > input[type='checkbox'] {
    width: 18px;
    height: 18px;

    &:checked {
      background-color: $main;
    }
  }

  &:hover {
    font-weight: 600;
  }
`;

const QuestionDetail = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const { id } = useParams();

  const [inquiry, setInquiry] = useState('');
  const [statusChecked, setStatusChecekd] = useState([false, false, false]);
  const [checkedValue, setCheckedValue] = useState('');

  const ChangeCheckedValue = e => {
    setCheckedValue(e.target.value);
  };
  const ModifyStatus = () => {
    const status = inquiry.status;
    if (!checkedValue) {
      toast({
        title: '문의상태가 수정되지 않았습니다.',
        description: '체크한 상태를 확인해주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (checkedValue && checkedValue !== status) {
      const config = {
        method: 'put',
        url: `${server.SERVER_URL}/inquiry/${id}?status=${checkedValue}`,
        headers: { Authorization: `Bearer ${admin.token}` },
      };

      axios(config)
        .then(response => {
          navigate('/questions');
          setTimeout(
            toast({
              title: '수정 완료!',
              description: '문의 상태가 수정되었습니다.',
              position: 'top-right',
              status: 'success',
              duration: 5000,
              isClosable: true,
            }),
            5000
          );
        })
        .catch(error => {
          console.error(error);
          if (error.response.status === 412) {
            localStorage.clear();
            toast({
              title: '토큰이 만료됐습니다.',
              description: '새로 로그인 해주세요!',
              position: 'top-right',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        });
    }
  };

  const fetchData = () => {
    const config = {
      method: 'post',
      url: `${server.SERVER_URL}/inquiry/list/search`,
      headers: { Authorization: `Bearer ${admin.token}` },
      data: {
        page: 1,
        count: 1000,
      },
    };

    axios(config)
      .then(response => {
        const data = response.data.data;

        const FilterData = data.filter(item => item.inquiry_uid === Number(id));
        const inquiry = FilterData[0];
        const status = inquiry.status;

        if (status === 'answered') {
          setStatusChecekd([true, false, false]);
        }
        if (status === 'checked') {
          setStatusChecekd([false, true, false]);
        }

        if (status === 'unchecked') {
          setStatusChecekd([false, false, true]);
        }

        setInquiry(inquiry);
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  useEffect(() => {
    // 토큰 검증 및 갱신 로직
    const verifyAndRefreshToken = async () => {
      try {
        await verifyTokenAndRefresh();
        // 토큰이 유효하거나 갱신되었다면, 문의 상세 데이터를 불러오는 로직을 계속 진행
        fetchData(); // fetchData는 문의 상세 데이터를 불러오는 함수
      } catch (error) {
        // 토큰 검증 실패 또는 갱신 실패 시의 처리 로직
        console.error('토큰 검증 및 갱신 중 오류 발생:', error);
        toast({
          title: '인증 오류',
          description: '세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        // 필요한 경우 로그인 페이지로 리다이렉트
      }
    };

    verifyAndRefreshToken();
  }, [toast]);

  return (
    <>
      <Box p={{ base: '0 36px', md: '120px 64px' }}>
        <Box maxW="1200px" m="0 auto">
          <QuestionBox>
            <h4>문의 날짜</h4>
            <p>{dayjs(inquiry.date).format('YYYY-MM-DD')}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>문의자</h4>
            <p>{inquiry.name}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>이메일</h4>
            <p>{inquiry.email}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>문의 유형</h4>
            <p>{inquiry.category}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>문의 제목</h4>
            <p>{inquiry.title}</p>
          </QuestionBox>
          <QuestionBox style={{ minHeight: '200px' }}>
            <h4>문의 내용</h4>
            <p>{inquiry.content}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>상태</h4>
            <Flex direction={{ base: 'column', sm: 'row' }} gridGap="15px">
              <QuestionLabel htmlFor="answered">
                <input
                  type="checkbox"
                  value="answered"
                  id="answered"
                  checked={statusChecked[0]}
                  onChange={e => {
                    setStatusChecekd([true, false, false]);
                    ChangeCheckedValue(e);
                  }}
                />
                답변 완료
              </QuestionLabel>
              <QuestionLabel htmlFor="checked">
                <input
                  type="checkbox"
                  value="checked"
                  id="checked"
                  checked={statusChecked[1]}
                  onChange={e => {
                    setStatusChecekd([false, true, false]);
                    ChangeCheckedValue(e);
                  }}
                />
                확인
              </QuestionLabel>
              <QuestionLabel htmlFor="unchecked">
                <input
                  type="checkbox"
                  value="unchecked"
                  id="unchecked"
                  checked={statusChecked[2]}
                  onChange={e => {
                    setStatusChecekd([false, false, true]);
                    ChangeCheckedValue(e);
                  }}
                />
                미확인
              </QuestionLabel>
            </Flex>
          </QuestionBox>

          <BtnBox>
            <Back onClick={() => navigate(-1)}>뒤로 가기</Back>
            <ModifyBtn onClick={onOpen}>수정</ModifyBtn>
          </BtnBox>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody
            textAlign={'center'}
            fontSize="1.2rem"
            fontWeight={600}
            padding="20px 24px 10px"
          >
            문의 상태를 수정하시겠습니까?
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <HStack>
              <ModifyBtn onClick={ModifyStatus}>확인</ModifyBtn>
              <DeleteCancelBtn cancel onClick={onClose}>
                취소
              </DeleteCancelBtn>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QuestionDetail;
