import React from 'react';
import { PayMethodBtn } from 'styles/ComponentStyle';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const SelectedTags = ({ filterMethod, HandleRemove }) => {
  return (
    <Wrap spacing={'10px'}>
      {filterMethod?.map((item) =>
        <WrapItem key={item}>
          <PayMethodBtn
            bg='#474554' borderColor='#fff' font='#fff'
            id={item} onClick={HandleRemove}>
            <CheckIcon w={2} h={2} />
            {item === 'none' && "결제 안함"}
            {item === 'nopassbook' && "무통장"}
            {item === 'kakao' && "카카오(현)"}
            {item === 'iamport' && "카카오(구)"}
            {item === 'inicis' && "카드(현)"}
            {item === 'innopay' && "카드(구)"}
          </PayMethodBtn>
        </WrapItem>
      )}
    </Wrap>
  )
}

export default SelectedTags;