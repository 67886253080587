import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Flex, StatLabel, StatNumber, StatHelpText } from '@chakra-ui/react';
import { StatBox } from 'styles/ComponentStyle';

export const DashBoard = ({ data, fillColor, statTitle, statText, sum }) => {
    return (
        <Flex direction='column' gap='20px' h={'100%'} mt='40px' overflow={'auto'}>
            <StatBox mb='40px'>
                <StatLabel>{statTitle}</StatLabel>
                <StatNumber>{sum} 명</StatNumber>
                <StatHelpText>{statText}</StatHelpText>
            </StatBox>
            <ResponsiveContainer width="100%" height="100%" minHeight={400}>
                <BarChart
                    margin={0}
                    width={500}
                    height={400}
                    maxBarSize={120}
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="회원수" fill={fillColor} />
                </BarChart>
            </ResponsiveContainer>
        </Flex>
    )
}

