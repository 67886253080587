import {
  Box,
  Button,
  Flex,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { Pagination } from 'Common/Pagination';
import IsDeleteModal from 'Common/IsDeleteModal';
import * as server from 'config/Config';
import AddGroupMemberModal from './AddGroupMemberModal';
import GroupDetailTable from './GroupDetailTable';
import { AdminLogOut } from 'Hook/CheckedValid';

const GroupDetail = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem('admin'));

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); // 현재 페이지;
  const [maxPage, setMaxPage] = useState(0);
  const offset = (currentPage - 1) * 30;

  // 체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  // 체크용 id 리스트
  const [idList, setIdList] = useState([]);
  // membershipList 기본

  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAddGroupMemberModal, setIsAddGroupMemberModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});

  const openAddGroupMemberModal = () => {
    setIsAddGroupMemberModal(true);
  };

  const location = useLocation();

  const DeleteGroups = async () => {
    try {
      setLoading(true);
      const checkedArray = idList.filter(item => checkedItems.includes(item));

      if (checkedArray.length === 0) {
        onClose();
        toast({
          title: '선택한 유저가 없어요!',
          description: '삭제할 유저를 선택해주세요.',
          position: 'top-right',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // 삭제 API 호출
      if (checkedArray.length > 0) {
        const deleteGroupsResponse = await axios.delete(
          `${server.SERVER_URL}/user?group_uid=${currentGroup.uid}`,
          {
            data: { user_uids: checkedArray },
            headers: {
              Authorization: `Bearer ${admin.token}`,
            },
          }
        );
      }

      setSearchList(
        searchList.filter(member => !checkedArray.includes(member.user_uid))
      );

      toast({
        title: '그룹 삭제 성공',
        description: `그룹 삭제에 성공했습니다.`,
        position: 'top-right',
        status: 'info',
        duration: 5000,
      });
      setIdList(idList.filter(id => !checkedArray.includes(id)));
      onClose();
    } catch (err) {
      console.error(err);
      toast({
        title: 'error!',
        description: `${err.message}`,
        position: 'top-right',
        status: 'error',
        duration: 5000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    // 토큰 상태 검증 및 갱신 로직
    const verifyAndRefreshToken = async () => {
      try {
        await verifyTokenAndRefresh();
        // 토큰 상태가 유효하면 데이터를 불러오는 로직 계속 진행
        const getGroupMemberListResponse = await axios.get(
          `${server.SERVER_URL}${location.pathname}`,
          {
            headers: {
              Authorization: `Bearer ${admin.token}`,
            },
          }
        );

        const newIdList = getGroupMemberListResponse.data.data.getUserList.map(
          user => user.user_uid
        );
        setSearchList(getGroupMemberListResponse.data.data.getUserList);
        setIdList(newIdList);
        setCurrentGroup(getGroupMemberListResponse.data.data.group);
      } catch (error) {
        // 토큰 상태 검증 실패 시의 로직 (예: 사용자 로그아웃 처리 및 로그인 페이지로 리다이렉션)
        console.error('토큰 검증 및 갱신 중 오류 발생:', error);
        toast({
          title: '인증 오류',
          description: '세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        navigate('/login'); // 로그인 페이지로 리다이렉션
      }
    };

    verifyAndRefreshToken();
  }, [navigate, toast, currentPage, admin.token, location.pathname]);

  return (
    <>
      <Box className="MemberContainer">
        <h1
          style={{
            maxWidth: '1300px',
            margin: '0 auto',
            paddingBottom: '20px',
            fontSize: '20px',
          }}
        >
          {currentGroup?.admin_user_email} 님의 {currentGroup.name} 그룹
        </h1>
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Box margin="15px 0">
            <form
              onSubmit={async e => {
                e.preventDefault();
                setCurrent(1);
                setLoading(true);
                const getSearchGroupMemberResponse = await axios.get(
                  `${server.SERVER_URL}/group/${currentGroup.uid}/user?name=${
                    keyword || null
                  }`,
                  {
                    headers: {
                      Authorization: `Bearer ${admin.token}`,
                    },
                  }
                );

                setSearchList(
                  getSearchGroupMemberResponse.data.data.getUserList
                );
                setIdList(
                  getSearchGroupMemberResponse.data.data.getUserList.map(
                    user => user.user_uid
                  )
                );
                setLoading(false);
              }}
            >
              <Flex
                className="SearchFlex"
                alignItems="center"
                justify="space-between"
                direction={{ base: 'column', sm: 'row' }}
              >
                <input
                  type={'text'}
                  placeholder="검색할 키워드를 입력해주세요"
                  value={keyword || ''}
                  onChange={e => {
                    e.stopPropagation();
                    setKeyword(e.target.value);
                  }}
                />
                <Button type="submit">
                  <SearchIcon />
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Flex justify="flex-end" mb={25} spacing="15px">
          <AddIcon
            onClick={openAddGroupMemberModal}
            w={5}
            h={5}
            style={{ cursor: 'pointer', marginRight: '15px' }}
          />
          <DeleteIcon
            onClick={onOpen}
            w={5}
            h={5}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <GroupDetailTable
                setCheckedItems={setCheckedItems}
                checkedItems={checkedItems}
                idList={idList}
                searchList={searchList}
              />
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination
          total={maxPage}
          setCurrent={setCurrent}
          currentPage={currentPage}
          toast={toast}
        />
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeleteGroups} />
      <AddGroupMemberModal
        setLoading={setLoading}
        isAddGroupMemberModal={isAddGroupMemberModal}
        setIsAddGroupMemberModal={setIsAddGroupMemberModal}
        currentGroup={currentGroup}
        searchList={searchList}
        setSearchList={setSearchList}
        setIdList={setIdList}
      />
    </>
  );
};

export default GroupDetail;
