import { InfoIcon } from '@chakra-ui/icons';
import { Checkbox } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

const GroupListTable = ({
  setCheckedItems,
  checkedItems,
  idList,
  searchList,
}) => {
  const CheckAll = e => {
    setCheckedItems(e.target.checked ? idList : []);
  };

  const CheckEach = (e, uid) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, uid]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== uid));
    }
  };

  return (
    <table className="MemberCustomTableStyle">
      <thead>
        <tr className="MemberCustom-tr MemberCustom-thead-tr">
          <th className="MemberCheckBox textCenter">
            <Checkbox
              name="all"
              value="all"
              colorScheme="blue"
              isChecked={checkedItems.length === idList.length}
              onChange={CheckAll}
            />
          </th>
          <th className="MemberCustom-th1 textLeft">그룹명</th>
          <th className="MemberCustom-th2 textLeft">그룹장</th>
          <th className="MemberCustom-th3 textCenter">생성일자</th>
          <th className="MemberCustom-th8 textCenter">관리</th>
        </tr>
      </thead>
      <tbody>
        {searchList.length !== 0 ? (
          searchList.map(item => (
            <tr key={item?.uid || item?.user_uid} className="MemberCustom-tr">
              <td className="CheckBox textCenter">
                <Checkbox
                  name="list"
                  value={item.uid}
                  colorScheme="blue"
                  isChecked={checkedItems.includes(item.uid)}
                  onChange={e => CheckEach(e, item.uid)}
                />
              </td>
              <td>{item?.name}</td>
              <td>{item?.admin_user_email}</td>
              <td className="textCenter">
                {dayjs(item?.create_at).format('YYYY-MM-DD')}
              </td>

              <td className="textCenter">
                <Link to={`/group/${item.uid}`}>
                  <InfoIcon w={5} h={5} />
                </Link>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>결과가 없습니다</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default GroupListTable;
