import React from 'react';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import "../styles/style.scss";

const Header = ({ onOpen }) => {
  const [isSmallerThan960] = useMediaQuery('(max-width: 960px)')

  return (
    <>
      {isSmallerThan960 && (
        <Box backgroundColor="#f9f9f9" padding="20px">
          <Flex
            justify={{ base: 'space-between', lg: 'flex-end' }}
            align="center"
          >
            <HamburgerIcon
              boxSize={7}
              onClick={onOpen}
              display={{ base: 'block', lg: 'none' }}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Header;
