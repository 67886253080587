import { AdminLogOut } from 'Hook/CheckedValid';
import axios from 'axios';
import * as server from 'config/Config';
import { jwtDecode } from 'jwt-decode';

export const requestSecureGet = async (url, token) => {
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return {
      data: response.data,
      config: { isSuccessful: response.status === 200 },
    };
  } catch (error) {
    return { config: { isSuccessful: false } };
  }
};

// 새 토큰을 요청하는 함수
export const refreshAuthToken = async () => {
  try {
    const response = await axios.get(`${server.SERVER_URL}/admin/user`);
    const newToken = response.data.token;
    localStorage.setItem('admin', JSON.stringify({ token: newToken }));
    return newToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return null;
  }
};

export const verifyTokenAndRefresh = async () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  const token = admin?.token;

  if (!token) {
    AdminLogOut();
    return false;
  }

  try {
    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      console.error('토큰 디코딩에 실패했습니다:', error);
      AdminLogOut();
      return false;
    }

    const tokenExp = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    if (tokenExp < currentTime) {
      const newToken = await refreshAuthToken();
      if (!newToken) {
        console.error('토큰 새로 고침에 실패했습니다.');
        AdminLogOut();
        return false;
      }
      // 기존 admin 객체의 다른 정보를 유지하면서 토큰만 갱신
      localStorage.setItem(
        'admin',
        JSON.stringify({ ...admin, token: newToken })
      );
      return true;
    } else {
      // 토큰이 만료되지 않았다면 여기서 true 반환
      return true;
    }
  } catch (error) {
    console.error('토큰 인증 중 오류가 발생했습니다:', error);
    AdminLogOut();
    return false;
  }
};
