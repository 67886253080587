import React from 'react';
import { Wrap, WrapItem} from '@chakra-ui/react';
import { PayMethodCheckbox } from 'styles/ComponentStyle';

const MethodChecks = ({
  payAllChecked,
  CheckPayFilteredAll,
  payFilter,
  setPayFilter,
  CheckPayFilterValue,
}) => {
  return (
    <Wrap spacing={'10px'}>
      <WrapItem>
      <PayMethodCheckbox
        name="all"
        value="iamport,innopay,nopassbook,none"
        colorScheme="gray"
        size='sm'
        bg={'#EDF2F7'}
        font="#1A202C"
        hoverbg={'#CBD5E0'}
        isChecked={payAllChecked}
        onChange={CheckPayFilteredAll}
      >
        전체
      </PayMethodCheckbox>
      </WrapItem>
      <WrapItem>
      <PayMethodCheckbox
        name="none"
        value="none"
        colorScheme="red"
        size='sm'
        bg={'#FED7D7'}
        font="#822727"
        hoverbg={'#FC8181'}
        isChecked={payFilter[0]}
        onChange={e => {
          setPayFilter([
            e.target.checked,
            payFilter[1],
            payFilter[2],
            payFilter[3],
            payFilter[4],
            payFilter[5],
          ]);
          CheckPayFilterValue(e);
        }}
      >
        결제 안함
      </PayMethodCheckbox>
      </WrapItem>
      <WrapItem>
      <PayMethodCheckbox
        name="iamport"
        value="iamport"
        colorScheme="yellow"
        size='sm'
        bg={'#FEFCBF'}
        font="#744210"
        hoverbg={'#F6E05E'}
        isChecked={payFilter[1]}
        onChange={e => {
          setPayFilter([
            payFilter[0],
            e.target.checked,
            payFilter[2],
            payFilter[3],
            payFilter[4],
            payFilter[5],
          ]);
          CheckPayFilterValue(e);
        }}
      >
        카카오(구)
      </PayMethodCheckbox>
      </WrapItem>
      <WrapItem>
      <PayMethodCheckbox
        name="kakao"
        value="kakao"
        colorScheme="yellow"
        size='sm'
        bg={'#FEFCBF'}
        font="#744210"
        hoverbg={'#F6E05E'}
        isChecked={payFilter[2]}
        onChange={e => {
          setPayFilter([
            payFilter[0],
            payFilter[1],
            e.target.checked,
            payFilter[3],
            payFilter[4],
            payFilter[5],
          ]);
          CheckPayFilterValue(e);
        }}
      >
        카카오(현)
      </PayMethodCheckbox>
      </WrapItem>
      <WrapItem>
      <PayMethodCheckbox
        name="innopay"
        value="innopay"
        colorScheme="blue"
        size='sm'
        bg={'#BEE3F8'}
        font="#2A4365"
        hoverbg={'#63B3ED'}
        isChecked={payFilter[3]}
        onChange={e => {
          setPayFilter([
            payFilter[0],
            payFilter[1],
            payFilter[2],
            e.target.checked,
            payFilter[4],
            payFilter[5],
          ]);
          CheckPayFilterValue(e);
        }}
      >
        카드(구)
      </PayMethodCheckbox>
      </WrapItem>
      <WrapItem>
      <PayMethodCheckbox
        name="inicis"
        value="inicis"
        colorScheme="blue"
        size='sm'
        bg={'#BEE3F8'}
        font="#2A4365"
        hoverbg={'#63B3ED'}
        isChecked={payFilter[4]}
        onChange={e => {
          setPayFilter([
            payFilter[0],
            payFilter[1],
            payFilter[2],
            payFilter[3],
            e.target.checked,
            payFilter[5],
          ]);
          CheckPayFilterValue(e);
        }}
      >
        카드(현)
      </PayMethodCheckbox>
      </WrapItem>
      <WrapItem>
      <PayMethodCheckbox
        name="nopassbook"
        value="nopassbook"
        colorScheme="green"
        size='sm'
        bg={'#C6F6D5'}
        font="#22543D"
        hoverbg={'#68D391'}
        isChecked={payFilter[5]}
        onChange={e => {
          setPayFilter([
            payFilter[0],
            payFilter[1],
            payFilter[2],
            payFilter[3],
            payFilter[4],
            e.target.checked,
          ]);
          CheckPayFilterValue(e);
        }}
      >
        무통장
      </PayMethodCheckbox>
      </WrapItem>
    </Wrap>
  );
};

export default MethodChecks;
