import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'Components/Header';
import {
  Box,
  useDisclosure,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useToast,
} from '@chakra-ui/react';
import Sider from './Sider.jsx';
import { AvatarBox } from './AvatarBox.jsx';
import { DrawerMenus } from './DrawerMenus.jsx';
import { LogOut } from 'styles/ComponentStyle.jsx';
import { AdminLogOut } from 'Hook/CheckedValid.jsx';
import { verifyTokenAndRefresh } from 'utill/tokenCheck.js';

const Layout = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // 토큰 상태를 검증하고 필요한 경우 토큰을 새로고침합니다.
    verifyTokenAndRefresh().catch(error => {
      toast({
        position: 'top-right',
        title: '토큰 상태 확인 중 문제가 발생했습니다.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    });
  }, [toast]);

  return (
    <Box minH="100vh">
      <Sider />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <Box
            transition="300ms ease"
            borderRight="1px"
            borderRightColor="gray.300"
            w="full"
            pos="fixed"
            h="full"
            paddingTop={{ base: '0' }}
            bg="#f9f9f9"
            overflow={'auto'}
            p="15px"
          >
            <Box>
              <CloseButton onClick={onClose} />
            </Box>
            <AvatarBox />
            <Box textAlign={'center'}>
              <LogOut onClick={AdminLogOut}>Logout</LogOut>
            </Box>
            <DrawerMenus onClose={onClose} />
          </Box>
        </DrawerContent>
      </Drawer>
      <Header onOpen={onOpen} />

      <Box
        backgroundColor="#f9f9f9"
        minH="100vh"
        marginLeft={{ base: '0', lg: '240px' }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
