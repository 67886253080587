import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import * as server from 'config/Config';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SearchIcon } from '@chakra-ui/icons';

const AddGroupModal = ({
  isAddGroupModalOpen,
  setIsAddGroupModalOpen,
  setIsExistEmail,
  isExistEmail,
  setSearchList,
  searchList,
  setIdList,
}) => {
  const toast = useToast();
  const admin = JSON.parse(localStorage.getItem('admin'));

  const [groupLeaderUid, setGroupLeaderUid] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const closeAddGroupModal = () => {
    setIsAddGroupModalOpen(false);
    reset({ groupEmail: '' });
  };

  const searchAccount = async () => {
    try {
      const response = await axios.post(
        `${server.SERVER_URL}/user/list/search`,
        {
          page: 1,
          count: 1,
          membershipList: [0, 1, 3, 6],
          serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
          keyword: watch('groupEmail'),
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      const existUser = response.data.data.find(
        userData => userData.user.email === watch('groupEmail')
      );

      if (existUser) {
        setIsExistEmail(true);

        setGroupLeaderUid(existUser.user.user_uid);
        return;
      }

      setIsExistEmail(false);
    } catch (err) {
      toast({
        title: '에러가 발생했습니다.',
        description: '이메일 검색 에러',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
  };

  const addGroup = handleSubmit(async data => {
    try {
      const response = await axios.post(
        `${server.SERVER_URL}/user/list/search`,
        {
          page: 1,
          count: 1,
          membershipList: [0, 1, 3, 6],
          serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
          keyword: watch('groupEmail'),
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      const existUser = response.data.data.find(
        userData => userData.user.email === watch('groupEmail')
      );

      if (existUser) {
        setIsExistEmail(true);
        setGroupLeaderUid(existUser.user.user_uid);
      } else {
        setIsExistEmail(false);
        return;
      }

      const createGroupResonse = await axios.post(
        `${server.SERVER_URL}/group`,
        {
          name: data.groupName,
          user_uid: groupLeaderUid,
        },
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      );

      setSearchList([...searchList, createGroupResonse.data.data.group]);

      setIdList(prev => {
        return [...prev, createGroupResonse.data.data.group.uid];
      });

      toast({
        title: '그룹이 생성되었습니다.',
        description: `${data.groupName} 그룹생성`,
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      closeAddGroupModal();
    } catch (err) {
      toast({
        title: '에러가 발생했습니다.',
        description: '그룹생성 에러',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error(err);
    }
  });

  return (
    <Modal isOpen={isAddGroupModalOpen} onClose={closeAddGroupModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>그룹 생성하기</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent={'center'} flexDirection={'column'}>
            <Flex alignItems={'center'}>
              <label style={{ width: '80px' }}>그룹명</label>
              <Input
                placeholder="group name"
                {...register('groupName', { required: true })}
              />
            </Flex>
            <Flex alignItems={'center'} marginTop={'20px'}>
              <label style={{ width: '80px', marginRight: '10px' }}>
                그룹장 이메일
              </label>
              <Input
                placeholder="group email"
                {...register('groupEmail', { required: true })}
              />
              <Button onClick={searchAccount}>
                <SearchIcon />
              </Button>
            </Flex>
            <Flex>
              <div style={{ width: '80px' }}></div>
              <FormControl isInvalid={!isExistEmail}>
                {isExistEmail && (
                  <FormHelperText>존재하는 이메일 입니다.</FormHelperText>
                )}
                {isExistEmail === null && ''}
                {isExistEmail === false && (
                  <FormErrorMessage>
                    존재하지 않는 이메일 입니다.
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={closeAddGroupModal}>
            닫기
          </Button>
          <Button colorScheme="blue" onClick={addGroup}>
            생성하기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGroupModal;
