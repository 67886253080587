import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  NumberInput,
  NumberInputField,
  Text,
  Select,
} from '@chakra-ui/react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { Back, BtnBox, ModifyBtn } from 'styles/ComponentStyle';
import * as server from 'config/Config';
import axios from 'axios';

const InfoDetailBox = styled(Flex)`
  padding: 15px;
  gap: 10px;

  > h4 {
    min-width: 100px;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
`;
const InfoBox = ({ user, membership, onOpen, toast }) => {
  const { id } = useParams();
  const admin = JSON.parse(localStorage.getItem('admin'));
  const navigate = useNavigate();
  const [isPutToken, setIsPutToken] = useState(false);
  const [isPutChatToken, setIsPutChatToken] = useState(false);
  const [drawToken, setDrawToken] = useState(0);
  const [chatToken, setChatToken] = useState(0);
  const [initialAuto, setInitialAuto] = useState('');
  const [autoPlan, setAutoPlan] = useState('');

  const HandlePutTokenModal = () => {
    setIsPutToken(!isPutToken);
  };

  const HandlePutChatTokenModal = () => {
    setIsPutChatToken(!isPutChatToken);
  };

  const HandleChangeToken = (token, tokenType) => {
    if (token === '') {
      toast({
        title: '적용할 숫자가 없습니다.',
        description: '숫자를 적어주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (token) {
      const config = {
        method: 'put',
        url: `${server.SERVER_URL}/user/plan/${tokenType}?user_uid=${id}`,
        headers: { Authorization: `Bearer ${admin.token}` },
        data: {
          token: token,
        },
      };

      axios(config)
        .then(response => {
          navigate(0);
          toast({
            title: '성공!',
            description: '토큰이 적용 되었습니다.',
            position: 'top-right',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(error => {
          console.error(error);
          const errorStatus = error.response.status;
          const errorResMessage = error.response.data.message;

          toast({
            title: '실패',
            description: `[${errorStatus}] ${errorResMessage}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const HandleAutoPlan = () => {
    let plan = parseInt(autoPlan);
    if (autoPlan === '') {
      toast({
        title: '선택한 플랜이 없습니다.',
        description: '플랜을 선택해주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (autoPlan) {
      const config = {
        method: 'put',
        url: `${server.SERVER_URL}/user/plan/autogpt?user_uid=${id}`,
        headers: { Authorization: `Bearer ${admin.token}` },
        data: {
          plan: plan,
        },
      };

      axios(config)
        .then(response => {
          navigate(0);
          toast({
            title: '성공!',
            description: '플랜이 적용 되었습니다.',
            position: 'top-right',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(error => {
          console.error(error);
          const errorStatus = error.response.status;
          const errorResMessage = error.response.data.message;

          toast({
            title: '실패',
            description: `[${errorStatus}] ${errorResMessage}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const fetchAutoData = async () => {
    const config = {
      method: 'get',
      url: `${server.SERVER_URL}/user/plan/autogpt?user_uid=${id}`,
      headers: { Authorization: `Bearer ${admin.token}` },
    };

    try {
      const response = await axios(config);
      setInitialAuto(response.data.data);
    } catch (error) {
      const errorStatus = error.response.status;
      const errorResMessage = error.response.data.message;
      setInitialAuto(errorResMessage);
    }
  };

  useEffect(() => {
    fetchAutoData();
  }, [admin.token]);

  return (
    <Box className="InfoPayContainer">
      <Box className="InfoContent">
        <Heading as="h4" size="lg" margin="15px 0 15px 15px">
          💡 Info
        </Heading>
        <InfoDetailBox>
          <h4>이름</h4>
          <p>{user.name}</p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>이메일</h4>
          <p>{user.email}</p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>가입일자</h4>
          <p>{`${dayjs(user.create_at).format('YYYY-MM-DD')}`}</p>
        </InfoDetailBox>
        <InfoDetailBox style={{ backgroundColor: '#f9f9f9' }}>
          <h4>결제내역</h4>
          {membership.bill_service === 'none' &&
            membership.current === 0 &&
            '멤버십을 결제하지 않은 회원입니다.'}
          {membership.bill_service === 'none' &&
            membership.current > 0 &&
            `라이팅젤 시리얼 넘버 쿠폰 등록`}

          {membership.bill_service !== 'none' && (
            <p
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                toast({
                  title: '준비 중',
                  description: '결제 로그 이전 준비 중!',
                  position: 'top-right',
                  status: 'info',
                  duration: 5000,
                  isClosable: true,
                });
              }}
            >
              보러가기
            </p>
          )}
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>구독상품</h4>
          <p>
            {/* 구독 x */}
            {membership.bill_service === 'none' &&
              membership.current === 0 &&
              '멤버십을 결제하지 않은 회원입니다.'}

            {/* 구독 X, 시리얼 넘버 등록  */}
            {membership.bill_service === 'none' &&
              membership.current > 0 &&
              `라이팅젤 ${membership.current}개월 쿠폰`}

            {/* 구독 중 */}
            {membership.bill_service !== 'none' &&
              membership.current > 0 &&
              `${membership.current}개월`}

            {/* 구독 후 해지 */}
            {membership.bill_service !== 'none' &&
              membership.current === 0 &&
              membership.before > 0 &&
              `${membership.before}개월`}
          </p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>이전 구독상품</h4>
          <p>
            {/* 구독 x */}
            {(membership.bill_service === 'none' ||
              membership.bill_service !== 'none') &&
              membership.before === 0 &&
              '이전 결제 상품이 없습니다.'}

            {/* 구독 X, 시리얼 넘버 등록  */}
            {membership.bill_service === 'none' &&
              membership.before > 0 &&
              `라이팅젤 ${membership.before}개월 쿠폰`}

            {/* 이전 구독 */}
            {membership.bill_service !== 'none' &&
              membership.before > 0 &&
              `${membership.before}개월`}

            {/* 구독 후 해지 */}
            {membership.bill_service !== 'none' &&
              membership.current === 0 &&
              membership.before > 0 &&
              `${membership.before}개월`}
          </p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>최초 결제일</h4>
          <p>
            {/* 구독 X */}
            {membership.bill_service === 'none' &&
              membership.current === 0 &&
              '멤버십을 구독하지 않은 회원입니다.'}

            {/* 구독 X , 시리얼 쿠폰 */}
            {membership.bill_service === 'none' &&
              membership.current > 0 &&
              `${dayjs(user.membership_recent_date).format('YYYY-MM-DD')}`}

            {/* 구독한 적 있음 / 구독 시작날짜 있음 */}
            {membership.bill_service !== 'none' &&
              membership.start_date !== null &&
              `${dayjs(membership.start_date).format('YYYY-MM-DD')}`}

            {/* 구독한 적 있음 / 구독 시작날짜 없음 */}
            {membership.bill_service !== 'none' &&
              membership.start_date === null &&
              `${dayjs(user.membership_recent_date).format('YYYY-MM-DD')}`}
          </p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>결제 업데이트</h4>
          <p>
            {/* 구독 x */}
            {user.membership_recent_date === null
              ? '멤버십을 구독하지 않은 회원입니다.'
              : `${dayjs(user.membership_recent_date).format('YYYY-MM-DD')}`}
          </p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>이용기간</h4>
          <p>
            {/* 결제 X */}
            {membership.bill_service === 'none' &&
              membership.current === 0 &&
              '멤버십을 구독하지 않은 회원입니다.'}

            {/* 결제 X , 시리얼 쿠폰 */}
            {membership.bill_service === 'none' &&
              membership.current > 0 &&
              `${dayjs(user.membership_recent_date).format(
                'YYYY-MM-DD'
              )} ~ ${dayjs(membership.next_date).format('YYYY-MM-DD')}`}

            {/* 결제O, startdate 없음 */}
            {membership.bill_service !== 'none' &&
              membership.start_date === null &&
              `${dayjs(user.membership_recent_date).format(
                'YYYY-MM-DD'
              )} ~ ${dayjs(membership.next_date).format('YYYY-MM-DD')}`}

            {/* 결제 O , startdate 있음 */}
            {membership.bill_service !== 'none' &&
              membership.start_date !== null &&
              `${dayjs(membership.start_date).format('YYYY-MM-DD')} ~ ${dayjs(
                membership.next_date
              ).format('YYYY-MM-DD')}`}
          </p>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>결제 수단</h4>
          <p>
            {membership.bill_service === 'none' &&
              membership.current === 0 &&
              '멤버십을 구독하지 않은 회원입니다.'}
            {membership.bill_service === 'none' &&
              membership.current > 0 &&
              '라이팅젤 시리얼 넘버 쿠폰'}
            {membership.bill_service === 'iamport' && '카카오페이'}
            {membership.bill_service === 'kakao' && '카카오페이'}
            {membership.bill_service === 'inicis' && '신용/체크카드 결제'}
            {membership.bill_service === 'innopay' && '신용카드/체크카드'}
            {membership.bill_service === 'nopassbook' && '무통장'}
          </p>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>라이팅젤 토큰</h4>
          <Flex gap="10px" direction="column">
            {membership.count > 0
              ? `${membership.count} token`
              : '토큰이 없습니다'}
          </Flex>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>드로잉젤 토큰</h4>
          <Flex gap="10px" direction="column">
            {user.draw_count > 0
              ? `${user.draw_count} token`
              : '토큰이 없습니다'}

            <Button
              size={'sm'}
              colorScheme="green"
              onClick={HandlePutTokenModal}
            >
              적용
            </Button>
            {isPutToken && (
              <Modal isOpen={isPutToken} onClose={HandlePutTokenModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>드로잉젤 토큰 적용</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text fontSize={'md'} mb="10px">
                      📌 적용할 토큰 숫자를 적어주세요!
                    </Text>
                    <NumberInput
                      value={drawToken}
                      onChange={value => setDrawToken(parseInt(value))}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme={'purple'}
                      onClick={() => HandleChangeToken(drawToken, 'drawingel')}
                    >
                      적용하기
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Flex>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>채팅젤 토큰</h4>
          <Flex gap="10px" direction="column">
            {user.chat_token > 0
              ? `${user.chat_token} token`
              : '토큰이 없습니다'}

            <Button
              size={'sm'}
              colorScheme="green"
              onClick={HandlePutChatTokenModal}
            >
              적용
            </Button>
            {isPutChatToken && (
              <Modal isOpen={isPutChatToken} onClose={HandlePutChatTokenModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>채팅젤 토큰 적용</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text fontSize={'md'} mb="10px">
                      📌 적용할 토큰 숫자를 적어주세요!
                    </Text>
                    <NumberInput
                      value={chatToken}
                      onChange={value => setChatToken(parseInt(value))}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme={'purple'}
                      onClick={() => HandleChangeToken(chatToken, 'chatingel')}
                    >
                      적용하기
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Flex>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>AUTO-GPT</h4>
          <Flex direction={'column'} gap="10px">
            <Text fontWeight={700}>
              {initialAuto?.curentPlan
                ? initialAuto?.curentPlan?.name
                : initialAuto
                ? initialAuto
                : '불러오는 중입니다'}
            </Text>
            <Flex direction={{ base: 'column', md: 'row' }} align={'center'}>
              <Select
                placeholder="📌 수정할 플랜을 선택해주세요!"
                onChange={e => setAutoPlan(e.target.value)}
              >
                <option value="1">FREE</option>
                <option value="2">BASIC</option>
                <option value="3">PREMIUM</option>
              </Select>
              <Button
                size={'sm'}
                colorScheme={'green'}
                w={{ base: '100%', md: '105px' }}
                ml={{ base: '0px', md: '10px' }}
                mt={{ base: '10px', md: '0' }}
                onClick={HandleAutoPlan}
              >
                적용
              </Button>
            </Flex>
          </Flex>
        </InfoDetailBox>
      </Box>
      <BtnBox>
        <Back
          onClick={() => {
            navigate(`/members`);
          }}
        >
          뒤로 가기
        </Back>
        <ModifyBtn onClick={onOpen}>수정</ModifyBtn>
      </BtnBox>
    </Box>
  );
};

export default InfoBox;
