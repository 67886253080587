import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { VStack, Flex } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  VscHome,
  VscOrganization,
  VscNewFolder,
  VscReport,
  VscNote,
  VscHubot,
} from 'react-icons/vsc';
import { MdOutlineMotionPhotosAuto } from 'react-icons/md';
import { FaLayerGroup } from 'react-icons/fa';

const Nav = styled(Flex)`
width: 100%;
flex-direction: column;
align-items : baseline;
cursor: pointer;
transition: all 200ms ease-in-out;
padding : 5px 15px;
border-radius: 5px;

div&:hover {
        background-color: #f9f9f9;
        color : #474554;
    }
}

ul, a {
    width: 100%;
}

ul {
    padding-left: 40px;
    padding-top: 10px;
    line-height: 28px;
  
    > a > li {
        list-style: none;
        &:hover {
            font-weight: 600;
        }
    }   
}

`;

const SiderMenus = () => {
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  return (
    <VStack w="100%" spacing="20px" mt="30px">
      <Nav>
        <Link to="/">
          <Flex align={'center'} gap="20px">
            <VscHome size="1.3em" />
            HOME
          </Flex>
        </Link>
      </Nav>
      <Nav>
        <Flex
          w="100%"
          align={'center'}
          gap="20px"
          justify={'space-between'}
          onClick={() => {
            setToggle1(!toggle1);
          }}
        >
          <Flex align={'center'} gap="15px">
            <VscOrganization size="1.3em" />
            회원
          </Flex>
          <ChevronDownIcon />
        </Flex>
        {toggle1 && (
          <ul>
            <Link to="/members">
              <li>회원 현황 조회</li>
            </Link>
            <Link to="/paymentlog">
              <li>결제 현황 조회</li>
            </Link>
            <Link to="/loginlog">
              <li>로그인 기록 조회</li>
            </Link>
          </ul>
        )}
      </Nav>
      <Nav>
        <Link to="/questions">
          <Flex align={'center'} gap="20px">
            <VscReport size="1.3em" />
            문의사항
          </Flex>
        </Link>
      </Nav>
      <Nav>
        <Link to="/prompts">
          <Flex align={'center'} gap="20px">
            <VscNewFolder size="1.3em" />
            프롬프트
          </Flex>
        </Link>
      </Nav>
      <Nav>
        <Link to="/chat_prompts">
          <Flex align={'center'} gap="20px">
            <VscHubot size="1.3em" />
            채팅 프롬프트
          </Flex>
        </Link>
      </Nav>
      <Nav>
        <Flex
          w="100%"
          align={'center'}
          gap="20px"
          justify={'space-between'}
          onClick={() => {
            setToggle2(!toggle2);
          }}
        >
          <Flex align={'center'} gap="15px">
            <VscNote size="1.3em" />
            시리얼넘버
          </Flex>
          <ChevronDownIcon />
        </Flex>
        {toggle2 && (
          <ul>
            <Link to="/createSerial">
              <li>시리얼넘버 생성</li>
            </Link>
            <Link to="/getSerial">
              <li>시리얼넘버 조회</li>
            </Link>
          </ul>
        )}
      </Nav>
      <Nav>
        <Flex
          w="100%"
          align={'center'}
          gap="20px"
          justify={'space-between'}
          onClick={() => {
            setToggle3(!toggle3);
          }}
        >
          <Flex align={'center'} gap="15px">
            <MdOutlineMotionPhotosAuto size="1.3em" />
            AUTO-GPT
          </Flex>
          <ChevronDownIcon />
        </Flex>
        {toggle3 && (
          <ul>
            <Link to="/autogpt/createSerial">
              <li>시리얼넘버 생성</li>
            </Link>
            <Link to="/autogpt/getSerial">
              <li>시리얼넘버 조회</li>
            </Link>
          </ul>
        )}
      </Nav>
      <Nav>
        <Link to="/group">
          <Flex align={'center'} gap="20px">
            <FaLayerGroup size="1.3em" />
            그룹 관리
          </Flex>
        </Link>
      </Nav>
    </VStack>
  );
};

export default SiderMenus;
