import React from 'react';
import { Flex,Checkbox } from '@chakra-ui/react';

const MembershipCheckbox = ({allChecked, CheckFilteredAll, filterChecked, setCheckedFilter,CheckFilterValue}) => {
    return(
        <Flex
        direction={{ base: 'column', md: 'row' }}
        className="MemberCheck"
      >
        <Checkbox
          name="all"
          value="0,1,3,6"
          colorScheme="blue"
          isChecked={allChecked}
          onChange={CheckFilteredAll}
        >
          전체
        </Checkbox>
        <Checkbox
          name="noMembership"
          value="0"
          colorScheme="blue"
          isChecked={filterChecked[0]}
          onChange={e => {
            setCheckedFilter([
              e.target.checked,
              filterChecked[1],
              filterChecked[2],
              filterChecked[3],
            ]);
            CheckFilterValue(e);
          }}
        >
          구독 안함
        </Checkbox>
        <Checkbox
          name="month1"
          value="1"
          colorScheme="blue"
          isChecked={filterChecked[1]}
          onChange={e => {
            setCheckedFilter([
              filterChecked[0],
              e.target.checked,
              filterChecked[2],
              filterChecked[3],
            ]);
            CheckFilterValue(e);
          }}
        >
          1개월
        </Checkbox>
        <Checkbox
          name="month3"
          value="3"
          colorScheme="blue"
          isChecked={filterChecked[2]}
          onChange={e => {
            setCheckedFilter([
              filterChecked[0],
              filterChecked[1],
              e.target.checked,
              filterChecked[3],
            ]);
            CheckFilterValue(e);
          }}
        >
          3개월
        </Checkbox>
        <Checkbox
          name="month6"
          value="6"
          colorScheme="blue"
          isChecked={filterChecked[3]}
          onChange={e => {
            setCheckedFilter([
              filterChecked[0],
              filterChecked[1],
              filterChecked[2],
              e.target.checked,
            ]);
            CheckFilterValue(e);
          }}
        >
          6개월
        </Checkbox>
      </Flex>
    )
}

export default MembershipCheckbox;