import React, { useEffect, useState, useCallback, Suspense, lazy } from 'react';
import axios from 'axios';
import { Box, Flex, Select, Input, useToast, Skeleton } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { ResetBtn } from 'styles/ComponentStyle';
import * as server from 'config/Config';
import { Pagination } from 'Common/Pagination';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';
const QuestionTable = lazy(() => import('./QuestionTable'));

const SearchBtn = styled.button`
  background-color: #b8c6db;
  border: 1px solid #b8c6db;
  border-radius: 5px;
  padding: 8px 15px;
  margin-left: 10px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
`;

const Questions = () => {
  const toast = useToast();
  const admin = JSON.parse(localStorage.getItem('admin'));
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); //현재 페이지;
  const [category, setCategory] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [listAll, setListAll] = useState('');

  const offset = (currentPage - 1) * 30;

  const SearchQuestion = useCallback(
    async e => {
      setLoading(true);
      if (category) {
        const config = {
          method: 'post',
          url: `${server.SERVER_URL}/inquiry/list/search`,
          headers: { Authorization: `Bearer ${admin.token}` },
          data: {
            page: currentPage,
            count: 1000,
            category: category,
            content: searchContent,
            search: searchKeyword,
          },
        };
        AxiosSearch(config);
      }

      if (!category) {
        const config = {
          method: 'post',
          url: `${server.SERVER_URL}/inquiry/list/search`,
          headers: { Authorization: `Bearer ${admin.token}` },
          data: {
            page: currentPage,
            count: 1000,
            content: searchContent,
            search: searchKeyword,
          },
        };
        await AxiosSearch(config);
      }
    },
    [category, searchContent, searchKeyword, currentPage]
  );

  const AxiosSearch = useCallback(async config => {
    await axios(config)
      .then(response => {
        const data = response.data.data;

        const orderData = data.sort((a, b) => b.inquiry_uid - a.inquiry_uid);
        setListAll(orderData);
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const HandleCategory = e => {
    setCategory(e.target.value);
  };

  const HandleSerachContent = e => {
    setSearchContent(e.target.value);
  };

  const HandleSearchKeyword = e => {
    setSearchKeyword(e.target.value);
  };

  const ResetData = () => {
    setCategory('default');
    setSearchContent('');
    setSearchKeyword('');
    fetchData();
  };

  const fetchData = useCallback(() => {
    const config = {
      method: 'post',
      url: `${server.SERVER_URL}/inquiry/list/search`,
      headers: { Authorization: `Bearer ${admin.token}` },
      data: {
        page: currentPage,
        count: 1000,
      },
    };

    axios(config)
      .then(response => {
        const data = response.data.data;

        const orderData = data.sort((a, b) => b.inquiry_uid - a.inquiry_uid);
        setListAll(orderData);
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 412) {
          localStorage.clear();
          toast({
            title: '토큰이 만료됐습니다.',
            description: '새로 로그인 해주세요!',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });
  }, [currentPage]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        await verifyTokenAndRefresh(); // 토큰 유효성 검사 및 갱신
        fetchData(); // 데이터 요청 로직
      } catch (error) {
        // 토큰 유효성 검사 및 갱신에 실패한 경우 처리
        toast({
          title: '인증 실패',
          description: '로그인 세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        // 필요한 경우 로그인 페이지로 리다이렉션
      }
    };

    initializeData(); // 컴포넌트 마운트 시 함수 실행
  }, []);

  return (
    <>
      <Box className="MemberContainer">
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          textAlign="center"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Flex maxW="600px">
            <Select defaultValue={'default'} onChange={HandleCategory}>
              <option value="default" disabled>
                유형을 선택해주세요
              </option>
              <option value="이용 문의">이용 문의</option>
              <option value="오류 신고">오류 신고</option>
              <option value="서비스 제안">서비스 제안</option>
              <option value="환불">환불</option>
              <option value="탈퇴">탈퇴</option>
              <option value="기타">기타</option>
            </Select>
            <ResetBtn onClick={ResetData}>전체보기</ResetBtn>
          </Flex>
          <form>
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              gridGap={'10px'}
              mt="10px"
            >
              <Input
                w="100%"
                maxW="500px"
                placeholder="문의 내용을 적어주세요."
                value={searchContent}
                onChange={HandleSerachContent}
              />
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                justify="space-between"
                align="center"
                w="100%"
                maxW="500px"
              >
                <Input
                  placeholder="회원명 및 이메일 주소를 정확하게 적어주세요."
                  value={searchKeyword}
                  onChange={HandleSearchKeyword}
                />
                <SearchBtn
                  type="submit"
                  onClick={e => {
                    e.preventDefault();
                    SearchQuestion();
                  }}
                >
                  <SearchIcon />
                </SearchBtn>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <QuestionTable offset={offset} listAll={listAll} />
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination
          total={listAll.length}
          setCurrent={setCurrent}
          toast={toast}
          currentPage={currentPage}
        />
      </Box>
    </>
  );
};

export default Questions;
