import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Apps from './routes/Apps.jsx';
import { RecoilRoot } from 'recoil';

const customTheme = extendTheme({
  breakpoints: {
    sm: "480px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily : "'Spoqa Han Sans Neo', 'sans-serif'",
      }
    }
  }
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

    <RecoilRoot>
      <ChakraProvider theme={customTheme} resetCSS={true}>
        <BrowserRouter>
          <Apps />
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>

  </React.StrictMode>
);

