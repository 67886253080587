import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, useDisclosure, useToast, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import dayjs from 'dayjs';
import * as server from 'config/Config';
import { Back, DeleteCancelBtn, ModifyBtn } from 'styles/ComponentStyle';
import IsDeleteModal from 'Common/IsDeleteModal';
import { AdminLogOut } from 'Hook/CheckedValid';
import { verifyTokenAndRefresh } from 'utill/tokenCheck';

const Container = styled(Box)`
  background-color: #fff;
  border: 1px solid #444;
  padding: 30px 20px;
`;

const ChatPromptDetail = () => {
  const admin = JSON.parse(localStorage.getItem('admin'));
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  const [isModify, setModify] = useState(false);
  const [prompts, setprompt] = useState({
    admin_uid: '',
    name: '',
    text: '',
    uid: '',
    create_at: '',
    update_at: '',
  });

  const { admin_uid, name, text, create_at, update_at } = prompts;

  const DeletePrompt = async () => {
    await axios
      .delete(`${server.SERVER_URL}/prompt_npc/${id}`, {
        headers: { Authorization: `Bearer ${admin.token}` },
      })
      .then(response => {
        navigate('/chat_prompts');
        setTimeout(
          toast({
            title: '성공',
            description: '삭제 되었습니다!',
            position: 'top-right',
            status: 'success',
            duration: 5000,
            isClosable: true,
          }),
          5000
        );
      })
      .catch(error => {
        console.error(error.response);
        toast({
          title: 'error!',
          description: `${error.message}`,
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const ModifyPrompt = () => {
    const isBlank = Object.values(prompts);

    if (isBlank.includes('') === true) {
      toast({
        title: '빈 칸이 남아 있습니다.',
        description: '빈 칸을 모두 채워주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      const config = {
        method: 'put',
        url: `${server.SERVER_URL}/prompt_npc/${id}`,
        headers: { Authorization: `Bearer ${admin.token}` },
        data: {
          name: name,
          text: text,
        },
      };

      axios(config)
        .then(response => {
          navigate('/chat_prompts');
          setTimeout(
            toast({
              title: '성공',
              description: '수정 되었습니다!',
              position: 'top-right',
              status: 'success',
              duration: 5000,
              isClosable: true,
            }),
            5000
          );
        })
        .catch(error => {
          console.error(error);
          toast({
            title: `${error.response.status} Error`,
            description: `${error.response.data.message}`,
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const SettingModify = () => {
    setModify(true);
  };

  const ChangePrompt = e => {
    setprompt({ ...prompts, [e.target.name]: e.target.value });
  };

  const fetchData = async () => {
    await axios
      .get(`${server.SERVER_URL}/prompt_npc/list?page=${1}&count=${100}`, {
        headers: { Authorization: `Bearer ${admin.token}` },
      })
      .then(response => {
        const list = response.data.data;
        const prompt = list.find(item => item.uid === id);

        setprompt({
          ...prompt,
          admin_uid: prompt.admin_uid,
          create_at: prompt.create_at,
          uid: prompt.uid,
          name: prompt.name,
          text: prompt.text,
          update_at: prompt.update_at,
        });
      })
      .catch(error => {
        console.error(error);
        toast({
          title: `${error.response.status} Error`,
          description: `${error.response.data.message}`,
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    // 토큰 상태를 검증하고 필요한 경우 갱신합니다.
    const initAuth = async () => {
      try {
        await verifyTokenAndRefresh(); // 토큰 검증 및 갱신 함수 호출
        fetchData(); // 토큰이 유효하면, 프롬프트 상세 정보를 불러오는 함수를 호출합니다.
      } catch (error) {
        // 검증 또는 갱신 중 오류 발생 시의 처리 로직
        toast({
          title: '세션 만료',
          description: '세션이 만료되었습니다. 다시 로그인 해주세요.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        AdminLogOut(); // 로그아웃 처리
        // 필요하다면 로그인 페이지로 리다이렉트
      }
    };

    initAuth();
  }, [toast]);

  return (
    <>
      <Box padding="48px" maxW="1200px" m="0 auto">
        <Container>
          <Flex w="100%" direction={'column'} className="makePromtLabelInput">
            <label htmlFor="name">서비스명</label>
            <input
              type="text"
              id="name"
              name="name"
              disabled={isModify ? false : true}
              value={name}
              onChange={ChangePrompt}
            />
          </Flex>
          <Flex w="100%" direction={'column'} className="makePromtLabelInput">
            <label htmlFor="writer">작성자</label>
            <input
              type="text"
              id="writer"
              name="admin_uid"
              disabled={true}
              value={admin_uid}
            />
          </Flex>

          <Flex direction={{ base: 'column', sm: 'row' }}>
            <Box w="100%" mb="23px">
              <Text w="150px" fontWeight={600} mb="5px">
                생성 날짜
              </Text>
              <Text w="100%">{dayjs(create_at).format('YYYY-MM-DD')}</Text>
            </Box>

            <Box w="100%" mb="23px">
              <Text w="150px" fontWeight={600} mb="5px">
                수정 날짜
              </Text>
              <Text>{dayjs(update_at).format('YYYY-MM-DD')}</Text>
            </Box>
          </Flex>

          <Flex
            w="100%"
            direction={'column'}
            className="makePromtLabelTextarea"
          >
            <label htmlFor="prompt">prompt </label>
            <textarea
              id="prompt"
              name="text"
              disabled={isModify ? false : true}
              value={text}
              onChange={ChangePrompt}
            />
          </Flex>

          <Flex
            align={'center'}
            justify={{ base: 'center', sm: 'flex-end' }}
            mt="45px"
            gridGap={'15px'}
          >
            <ModifyBtn onClick={isModify ? ModifyPrompt : SettingModify}>
              {isModify ? '수정완료' : '수정'}
            </ModifyBtn>
            <DeleteCancelBtn onClick={onOpen}>삭제</DeleteCancelBtn>
          </Flex>
        </Container>
        <Box textAlign={'right'} mt="15px">
          <Link to="/chat_prompts">
            <Back>목록</Back>
          </Link>
        </Box>
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeletePrompt} />
    </>
  );
};

export default ChatPromptDetail;
